#img-cart-item {
    width: 150px;
    height: auto;
    /* aspect-ratio: 4/3; */
    object-fit: cover;
}


.cart__container {
    background-color: #E3E6E6;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.cart__content {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart__left, .cart__right {
    padding: 20px;
}

.cart__left > span {
    font-size: 28px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: block;
}

.cart__items {
    margin-bottom: 20px;
}

.cart__total {
    display: flex;
    justify-content: flex-end;
}

.total {
    font-size: 18px;
}

.total > span {
    font-weight: 700;
    color: #007185;
}

.cart__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cart__checkout {
    width: 100%;
    padding: 10px 15px;
    background-color: #FFD814;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cart__checkout:hover {
    background-color: #dbba16;
}

.cart__no-items {
    max-width: 1000px;
    min-width: 800px;
    max-height: 800px;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart__no-items > span {
    font-size: 24px;
    display: block;
    margin-bottom: 20px;
}

.cart__no-items > p > span {
    color: #007185;
    cursor: pointer;
    text-decoration: underline;
}

.cart__item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.295);
    padding: 20px 0;
}

.item__left {
    display: flex;
    align-items: center;
    justify-content: center;
}

#img-cart-item {
    max-width: 100%;
    cursor: pointer;
}

.item__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item__details {
    margin-bottom: 20px;
}

.item__name {
    font-size: 18px;
    cursor: pointer;
    color: #007185;
    margin-bottom: 10px;
}

.item__name:hover {
    text-decoration: underline;
}

.item__price {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.item__stock {
    font-size: 14px;
    margin-bottom: 10px;
}

.item__prime {
    max-width: 70px;
}

.item__edit-delete {
    display: flex;
    align-items: center;
    gap: 10px;
}

.item__edit-delete > span {
    color: #007185;
    cursor: pointer;
    text-decoration: underline;
}

.checkout__container {
    padding: 2rem;
    background-color: #E3E6E6;
}


.checkout__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout__header img {
    height: 50px;
}

.checkout__header p {
    font-weight: bold;
    font-size: 1.5rem;
}

.checkout__content-container {
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.checkout__content {
    display: flex;
    gap: 2rem;
}

.checkout__left, .checkout__right {
    padding: 1rem;
}

.checkout__address h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.checkout__address-content p {
    margin-bottom: 0.5rem;
}

.checkout__items h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.checkout__item {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
}

.checkout__item-left img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.checkout__item-right {
    flex: 1;
}

.lock-img {
    max-width: 1.5rem;
    max-height: 1.5rem;
}

.checkout__right-summary h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.checkout__right-summary-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.checkout__right-summary-left, .checkout__right-summary-right {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.cart__checkout {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}


@media (max-width: 768px) {
    .checkout__content {
        flex-direction: column;
    }
}
