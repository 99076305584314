.product-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
    border: 1px solid #888c8c;
    background-color: #f0f0f0;
    border-radius: 6px;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 50px;

}

.product-form-header {
    display: flex;
    justify-content: center;
}

.product-form-header > img {
    max-width: 200px;
}

.product-form-header > span {
    font-size: 28px;
}

.product-form {
    margin-top: 15px;
    border: 1px solid #888c8c;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 6px;
}

.product-form > span {
    color: #d8b710;
    font-size: 28px;
}

.product-form > p {
    font-size: 14px;
    padding: 8px 0 12px 0;
}

.product-form > form {
    display: flex;
    flex-direction: column;
    padding: 0 100px 0 0;
}

.product-form-line {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 2rem;
    margin: 12px 0 5px 0;
    align-items: center;
}

.product-form-line > label {
    display: flex;
    justify-self: flex-end;
}

.line-long,
.input-wrapper > select,
.input-wrapper > input,
.input-wrapper > div > input
{
    height: 30px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #888c8c;
    padding-left: 5px;
}

.input-wrapper > input:focus,
.input-wrapper > div > input:focus,
.input-wrapper > textarea:focus {
    background-color: #F7FEFF;
    border: 1px solid #e77600;
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
}

.input-wide {
    width: 250px;
}

.input-thin {
    width: 80px;
    align-items: start;
}

.list-product {
    align-self: center;
    border-radius: 15px;
    border: none;
    height: 28px;
    width: 100px;
    background-color: #ffd814;
    cursor: pointer;
    font-size: 12px;
    margin: 4px;
}

.samazon-logo-form {
    position: relative;
    top: 29px;
    max-width: 10rem;
}

.create-a-product {
    font-size: 25px;
    margin-left: 5px;
    font-weight: bold;
}

.product-form-quantity {
    height: 30px;
    border-radius: 6px;
    border: 1px solid #888c8c;
    outline: none;
}

.input-description {
    height: 150px;
    padding: 6px;
    width: 230px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #888c8c;
    padding-left: 5px;
    font-family: "Amazon Ember",Arial,sans-serif;
}

.input-description textarea:focus{
    background-color: #F7FEFF;
    border: 1px solid #e77600;
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
}

.product-form-submit {
    display: flex;
    justify-content: flex-end;
}

.product-form-submit > button {
    border: none;
    background-color: #FFD814;
    padding: 8px 14px;
    border-radius: 10px;
    cursor: pointer;
}

.product-form-submit > button:hover {
    background-color: #dbba16;
}

.form-errors {
    color: red;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    position: absolute;
    left: 160px;
    top: 0;
    white-space: nowrap;
    margin-left: 10px; 
}

.form-errors > span {
    font-size: 16px;
    font-style: italic;
    padding: 0 2px;
}

.form__file-pic > img {
    max-width: 200px;
}

.image-list-product {
    margin-left: 10px;
    border-left:black 1px solid;
    padding-left: 20px;
}

.name-description-inputs {
    margin-right: 10px;
}

.form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form-submit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
}

.input-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.input-wrapper > label{
    margin-right: 40px;
}
