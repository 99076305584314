/* .profile-dropdown {
  position: absolute;
} */

.hidden {
  display: none;
}

.samazon-logo-img {
  max-width: 10rem;
}

.USA-Flag {
  max-width: 1.5rem;
  margin-right: .3rem;
}

.main-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #131A22;
  color: white;
  padding: 10px 20px;
}

.main-nav-bar div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.deliver-address {
  font-size: 14px;
  max-width: 10rem;
  margin-right: 2rem;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  background-color: white;
  border-radius: 5px;
}

.search-bar-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.language {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.shoppingcart {
  max-width: 2.5rem;
}

.fa-magnifying-glass {
  background-color: #FEBD69;
  color: black;
  border: none;
  outline: none;
  padding: 8px 12px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.nav-ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

.returns-orders {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.cart {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 14px;
}

.profile-button {
  border: none;
}

.user-button {
  background-color: transparent;
  border: none;
  margin-right: 9px;
  color: white;
  font-weight: bold;
}

.user-profile important{
  display: flex;
  align-items: start;
}

.user-button:hover {
  cursor: pointer;
}

.profile-dropdown button {
  color: white;
  background-color: #f0c14b;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-dropdown-li {
  color: black;
}

.profile-dropdown button:hover {
  background-color: #FA8900;
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

#profile-dropdown-profile-butt {
  padding: 12px 9px 12px 0px;
}

.fa-user-circle {
  font-size: 18px;
  margin-left: -3px;
  margin-right: -3px;
}

/* Profile Modal Styles */
.profile-dropdown {
  position: absolute;
  background-color: white;
  padding-right: 20px;
  padding-left: 20px;
  list-style-type: none;
  top: 40px;
  right: 50px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
              rgba(17, 17, 26, 0.1) 0px 8px 24px,
              rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.cart-items-num {
  text-decoration: none;
  font-size: 15px;
  color: #f08804;
  position: absolute;
  right: 30px;
  top: 20px;
}

.categories-nav {
  background-color: #232F3E;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  font-size: 14px;
  padding: 10px 0;
}

.categories-nav .category-item {
  cursor: pointer;
}


@media (max-width: 740px){
  .cart-items-num {
    top:35px;
  }
}
