.product-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

}

.product-details-display-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr .8fr;
    gap: 1.2rem;
    max-width: 1500px;
    padding: 20px;
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid hsla(0,0%,50.2%,.295);

}

.product-details-display-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-details-display-img > img {
    padding: 12px;
    min-width: 300px;
    max-width: 440px;
    max-height: 550px;
}

.product-details-name {
    font-size: 24px;

}

.product-details-price {
    font-size: 13px;
    display: flex;
    padding-top: 5px;
    border-top: 1px solid hsla(0,0%,50.2%,.295);
}

.buy-new {
    font-size: 12px;
}

.product-details-price > p {
    padding-top: 5px;
}

.product-details-price > span {
    font-size: 28px;
}

.product-details-price-addtocart {
    font-size: 28px;
}

.product-details-returns {
    font-size: 14px;
    color: #007185;
    border-bottom: 1px solid hsla(0,0%,50.2%,.295);
}

.product-details-returns-cart {
    font-size: 14px;
    color: #007185;
}

.secure-transaction{
    color: #007185;
}

.deliver-to {
    font-size: 14px;
    color: #007185;
    margin-top: .5rem;
}

.free-delivery {
    margin-top: .5rem;
}

.product-details-description {
    margin-top: 20px;
}

.product-details-description > h4 {
    font-size: 17px;
    padding-bottom: 4px;
}

.product-details-description-list {
    padding-left: 18px;
}

.product-details-description-list > li {
    padding: 3px 0;
    font-size: 15px;
}

.prime-logo {
    max-width: 4rem;
    margin-bottom: .6rem;
}

.product-samazon-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 15px 0;
    grid-gap: 10px;
    gap: 8px;
}

.product-addtocart-left {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    font-size: 13px;
    color: gray;
}

.product-addtocart-right {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    font-size: 13px;
}

.stock {
    font-size: 21px;
    color: #007600;
    margin-top: .5rem;
}

.stock-quantity {
    color: #B53415;
    margin-bottom: .5rem;
}

.add-to-cart {
    width: 95%;
    align-self: center;
    border-radius: 15px;
    border: none;
    height: 28px;
    background-color: #ffd814;
    cursor: pointer;
    font-size: 12px;
    margin: 4px;
}

.add-to-cart-section {
    display: flex;
    border: 1px solid hsla(0,0%, 50.2%, .295);
    border-radius: 7px;
    padding: 17px;
    flex-direction: column;
    height: fit-content;
    max-width: 250px;
}

.update-delete-but {
    align-self: center;
    border-radius: 15px;
    border: none;
    height: 28px;
    background-color: #ffd814;
    cursor: pointer;
    font-size: 12px;
    margin: 4px;
}
