.footer-container {
    background-color: #232F3E;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    /* min-height: 100vh; */

  }

  .to-top {
    display: flex;
    background-color: #304157;
    color: white;
    justify-content: center;
    height: 40px;
    width: 100%;
    font-size: 13px;
    cursor: pointer;
    align-items: center;
    margin-top: -15px;
  }

  .to-top:hover {
    background-color: #304157ec;
  }

  .footer-info {
    display: flex;
    gap: 2rem;
    padding: 20px 0;
    align-items: center;
  }

  .github-linkedin {
    display: flex;
    gap: 2rem;
    padding: 20px 0;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }

  .github-linkedin i {
    color: white !important;
}

  .fa-github :after {
    text-decoration: none;
    color: white;
  }

  .sam-info {
    margin-top: 10px;
    margin-bottom: -35px;
  }

  .sam-info span {
    font-size: 26px;
    bottom: 28px;
    left: 10px;
    position: relative;
  }

  .footer-info > a {
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
