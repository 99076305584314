.all-products {
    display: flex;
    justify-content: center;
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
}

.product-card {
    width: 300px;
    height: 500px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.product-card:hover {
    transform: scale(1.03);
}

.product-card img {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.product-card h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}


.product-card p {
    font-size: 14px;
    margin-bottom: 8px;
    color: #555;
}
