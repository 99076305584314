body {
    margin: 0;
    font-family: "Amazon Ember",Arial,sans-serif;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    flex: 1;
  }
